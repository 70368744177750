import { graphql } from 'gatsby';
import React from 'react';
import PageTemplate from '@matix/gatsby-theme-core/src/components/templates/page-template';
const PageQuery = (props) => {
   return <PageTemplate {...props}></PageTemplate>;
};

export const pageQuery = graphql`
   query pageTemplateEventThemeCore($locale: String, $uuid: String) {
      pageQuery: matixMdx(frontmatter: { uuid: { eq: $uuid } }, locale: { eq: $locale }) {
         id
         locale
         ...ThemeCoreFragment
         ...MatixEventFragment
         ...EventThemeEvents
         ...CardEventThemeCore
      }
      pageQueryLocale: allMatixMdx(filter: { frontmatter: { uuid: { eq: $uuid } } }) {
         nodes {
            ...PageQueryLocaleThemeCore
         }
      }
   }
`;

export default PageQuery;
